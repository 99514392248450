/* *{
  border: solid red;
} */

/* HEADER STYLES */
header{
  display: flex;
  justify-content: space-between;
  margin: 1.3rem 3rem;
}

.logo {
  /* margin-top: 2rem; */
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  color: black;
}


.nav{
  display: flex;
  border-radius: 5%;
  /* background-color: aliceblue; */
  margin-top: 10px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.nav a{
  margin-top: .9rem;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  text-decoration: none;
  color: black
}

.nav a:hover{
  color:blue;
}

/* CAROUSEL STYLING */
.wrapper{
  margin-top: 100px;
  margin-left: 1rem;
  height: 700px;
/*   border: 1px solid blue; */
  display: flex;
  overflow-x: auto;
}

.wrapper::-webkit-scrollbar{
  width: 0;
}

.item img{
  object-fit: cover;
  height: 700px;
  /* min-width: 30rem; */
  margin-right: .6rem; 
}

.imageInfo div {
  margin: 70% 2rem 2rem 2rem;
  width: 500px;
}

.item {
  margin-right: 1200px; 

}

.wrapper .item{
  /* width: 600px; */
  height: 700px;
  /* line-height: 110px; */
  /* text-align: center; */
  /* background-color: lightgrey; */
  margin-right: 12px; 
  object-fit: cover;
}